import debounce from 'lodash.debounce';

export default {
    name: 'Search',
    props: ['search'],
    data() {
        return {
            currentSearch: this.search
        };
    },
    watch: {
        currentSearch: {
            handler: debounce(function() { this.$emit('search', this.currentSearch); }, 500)
        }
    },
};