import { render, staticRenderFns } from "./inputtoggle.vue?vue&type=template&id=63cf56ac&"
import script from "./inputtoggle.js?vue&type=script&lang=js&"
export * from "./inputtoggle.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/arthur/Projecten/dotnetcore/Telefoonaanname.CustomerPortal/Frontend/CustomerPortal.Portal/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('63cf56ac', component.options)
    } else {
      api.reload('63cf56ac', component.options)
    }
    module.hot.accept("./inputtoggle.vue?vue&type=template&id=63cf56ac&", function () {
      api.rerender('63cf56ac', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "ClientApp/components/common/inputs/inputtoggle.vue"
export default component.exports