var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-nav" }, [
    _c("nav", { staticClass: "navbar navbar-expand-lg navbar-light" }, [
      _c(
        "div",
        {
          staticClass: "collapse navbar-collapse",
          attrs: { id: "navbarSupportedContent" }
        },
        [
          _vm.isAdministrator
            ? _c("ul", { staticClass: "navbar-nav" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass: "nav-item",
                    class: { active: _vm.subIsActive(_vm.$t("path.alert")) }
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "br-menu-link",
                        attrs: {
                          to: { name: "alert" },
                          "active-class": "active show-sub"
                        }
                      },
                      [
                        _c("div", { staticClass: "br-menu-item" }, [
                          _c("i", {
                            staticClass:
                              "menu-item-icon icon ion-ios-person-add tx-22"
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "menu-item-label" }, [
                            _vm._v(_vm._s(_vm.$t("alert")))
                          ])
                        ])
                      ]
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isAdministrator
            ? _c("ul", { staticClass: "navbar-nav" }, [
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    class: { active: _vm.subIsActive(_vm.$t("path.companies")) }
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "br-menu-link",
                        attrs: {
                          to: { name: "companies" },
                          "active-class": "active show-sub"
                        }
                      },
                      [
                        _c("div", { staticClass: "br-menu-item" }, [
                          _c("i", {
                            staticClass:
                              "menu-item-icon icon ion-ios-person-add tx-22"
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "menu-item-label" }, [
                            _vm._v(_vm._s(_vm.$t("title.companies")))
                          ])
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { class: { active: _vm.subIsActive(_vm.$t("path.log")) } },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "br-menu-link",
                        attrs: {
                          to: { name: "log" },
                          "active-class": "active show-sub"
                        }
                      },
                      [
                        _c("div", { staticClass: "br-menu-item" }, [
                          _c("i", {
                            staticClass:
                              "menu-item-icon icon ion-ios-person-add tx-22"
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "menu-item-label" }, [
                            _vm._v(_vm._s(_vm.$t("title.log")))
                          ])
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    class: {
                      active: _vm.subIsActive(
                        _vm.$t("path.manage_administrator")
                      )
                    }
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "br-menu-link",
                        attrs: {
                          to: { name: "administrator" },
                          "active-class": "active show-sub"
                        }
                      },
                      [
                        _c("div", { staticClass: "br-menu-item" }, [
                          _c("i", {
                            staticClass:
                              "menu-item-icon icon ion-ios-person-add tx-22"
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "menu-item-label" }, [
                            _vm._v(_vm._s(_vm.$t("title.administrator")))
                          ])
                        ])
                      ]
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isWorkerAdmin
            ? _c("ul", { staticClass: "navbar-nav" }, [
                _vm.useradmin.username
                  ? _c("li", [
                      _c(
                        "a",
                        {
                          staticClass: "cursorpointer btn btn-pink",
                          staticStyle: { color: "#fff" },
                          on: { click: _vm.adminlogin }
                        },
                        [_vm._v("«" + _vm._s(_vm.$t("actions.backtoadmin")))]
                      ),
                      _vm._v(" "),
                      _c("hr")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._m(2),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    class: { active: _vm.subIsActive(_vm.$t("path.message")) }
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "br-menu-link ",
                        attrs: {
                          to: { name: "message" },
                          "active-class": "active show-sub"
                        }
                      },
                      [
                        _c("div", { staticClass: "br-menu-item" }, [
                          _c("i", {
                            staticClass:
                              "menu-item-icon icon ion-ios-person-add tx-22"
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "menu-item-label d-block d-sm-none float-left",
                              attrs: {
                                "data-toggle": "collapse",
                                "data-target": "#navbarSupportedContent"
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("title.message")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "menu-item-label d-none d-sm-block float-left"
                            },
                            [_vm._v(_vm._s(_vm.$t("title.message")))]
                          ),
                          _vm._v(" "),
                          _vm.unreadcount > 0
                            ? _c(
                                "span",
                                {
                                  staticClass: "badge badge-secondary",
                                  staticStyle: { "margin-left": "5px" }
                                },
                                [_vm._v(_vm._s(_vm.unreadcount))]
                              )
                            : _vm._e()
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass: "d-none d-sm-block",
                    class: {
                      active: _vm.subIsActive(_vm.$t("path.workeralert"))
                    }
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "br-menu-link",
                        attrs: {
                          to: { name: "workeralert" },
                          "active-class": "active show-sub"
                        }
                      },
                      [
                        _c("div", { staticClass: "br-menu-item" }, [
                          _c("i", {
                            staticClass:
                              "menu-item-icon icon ion-ios-person-add tx-22"
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "menu-item-label" }, [
                            _vm._v(_vm._s(_vm.$t("alert")))
                          ])
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass: "d-none d-sm-block",
                    class: { active: _vm.subIsActive(_vm.$t("path.invoice")) }
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "br-menu-link",
                        attrs: {
                          to: { name: "invoice" },
                          "active-class": "active show-sub"
                        }
                      },
                      [
                        _c("div", { staticClass: "br-menu-item" }, [
                          _c("i", {
                            staticClass:
                              "menu-item-icon icon ion-ios-person-add tx-22"
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "menu-item-label" }, [
                            _vm._v(_vm._s(_vm.$t("title.invoice")))
                          ])
                        ])
                      ]
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isWorkerUser
            ? _c("ul", { staticClass: "navbar-nav" }, [
                _vm.useradmin.username
                  ? _c("li", [
                      _c(
                        "a",
                        {
                          staticClass: "cursorpointer",
                          on: { click: _vm.adminlogin }
                        },
                        [_vm._v(_vm._s(_vm.$t("actions.backtoadmin")))]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._m(3),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    class: {
                      active: _vm.subIsActive(_vm.$t("path.workerinstruction"))
                    }
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "br-menu-link",
                        attrs: {
                          to: { name: "workerinstruction" },
                          "active-class": "active show-sub"
                        }
                      },
                      [
                        _c("div", { staticClass: "br-menu-item" }, [
                          _c("i", {
                            staticClass:
                              "menu-item-icon icon ion-ios-person-add tx-22"
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "menu-item-label d-block d-sm-none",
                              attrs: {
                                "active-class": "active show-sub",
                                "data-toggle": "collapse",
                                "data-target": "#navbarSupportedContent"
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("workerinstuction")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "menu-item-label d-none d-sm-block",
                              attrs: { "active-class": "active show-sub" }
                            },
                            [_vm._v(_vm._s(_vm.$t("workerinstuction")))]
                          )
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { class: { active: _vm.subIsActive(_vm.$t("path.worker")) } },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "br-menu-link",
                        attrs: { to: { name: "worker" } }
                      },
                      [
                        _c("div", { staticClass: "br-menu-item" }, [
                          _c("i", {
                            staticClass:
                              "menu-item-icon icon ion-ios-person-add tx-22"
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "menu-item-label d-block d-sm-none",
                              attrs: {
                                "active-class": "active show-sub",
                                "data-toggle": "collapse",
                                "data-target": "#navbarSupportedContent"
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("account")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "menu-item-label d-none d-sm-block",
                              attrs: { "active-class": "active show-sub" }
                            },
                            [_vm._v(_vm._s(_vm.$t("account")))]
                          )
                        ])
                      ]
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isWorkerAdmin
            ? _c("ul", { staticClass: "navbar-nav" }, [
                _vm._m(4),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    class: {
                      active: _vm.subIsActive(_vm.$t("path.workerinstruction"))
                    }
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "br-menu-link",
                        attrs: {
                          to: { name: "workerinstruction" },
                          "active-class": "active show-sub"
                        }
                      },
                      [
                        _c("div", { staticClass: "br-menu-item" }, [
                          _c("i", {
                            staticClass:
                              "menu-item-icon icon ion-ios-person-add tx-22"
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "menu-item-label d-block d-sm-none",
                              attrs: {
                                "active-class": "active show-sub",
                                "data-toggle": "collapse",
                                "data-target": "#navbarSupportedContent"
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("workerinstuction")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "menu-item-label d-none d-sm-block",
                              attrs: { "active-class": "active show-sub" }
                            },
                            [_vm._v(_vm._s(_vm.$t("workerinstuction")))]
                          )
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { class: { active: _vm.subIsActive(_vm.$t("path.worker")) } },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "br-menu-link",
                        attrs: { to: { name: "worker" } }
                      },
                      [
                        _c("div", { staticClass: "br-menu-item" }, [
                          _c("i", {
                            staticClass:
                              "menu-item-icon icon ion-ios-person-add tx-22"
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "menu-item-label d-block d-sm-none",
                              attrs: {
                                "active-class": "active show-sub",
                                "data-toggle": "collapse",
                                "data-target": "#navbarSupportedContent"
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("account")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "menu-item-label d-none d-sm-block",
                              attrs: { "active-class": "active show-sub" }
                            },
                            [_vm._v(_vm._s(_vm.$t("account")))]
                          )
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass: "d-none d-sm-block",
                    class: {
                      active: _vm.subIsActive(_vm.$t("path.workercompany"))
                    }
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "br-menu-link",
                        attrs: {
                          to: { name: "workercompany" },
                          "active-class": "active show-sub"
                        }
                      },
                      [
                        _c("div", { staticClass: "br-menu-item" }, [
                          _c("i", {
                            staticClass:
                              "menu-item-icon icon ion-ios-person-add tx-22"
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "menu-item-label" }, [
                            _vm._v(_vm._s(_vm.$t("organization")))
                          ])
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass: "d-none d-sm-block",
                    class: {
                      active: _vm.subIsActive(_vm.$t("path.staffmembers"))
                    }
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "br-menu-link",
                        attrs: {
                          to: {
                            name: "staffmembers",
                            params: { workerid: _vm.user.workerid }
                          },
                          "active-class": "active show-sub"
                        }
                      },
                      [
                        _c("div", { staticClass: "br-menu-item" }, [
                          _c("i", {
                            staticClass:
                              "menu-item-icon icon ion-ios-person-add tx-22"
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "menu-item-label" }, [
                            _vm._v(_vm._s(_vm.$t("staffmembers")))
                          ])
                        ])
                      ]
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("ul", { staticClass: "navbar-nav" }, [
            _vm._m(5),
            _vm._v(" "),
            _c(
              "li",
              { class: { active: _vm.subIsActive(_vm.$t("path.feedback")) } },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "br-menu-link",
                    attrs: {
                      to: { name: "feedback" },
                      "active-class": "active show-sub"
                    }
                  },
                  [
                    _c("div", { staticClass: "br-menu-item" }, [
                      _c("i", {
                        staticClass:
                          "menu-item-icon icon ion-ios-person-add tx-22"
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "menu-item-label d-block d-sm-none",
                          attrs: {
                            "data-toggle": "collapse",
                            "data-target": "#navbarSupportedContent"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("feedback")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "menu-item-label d-none d-sm-block" },
                        [_vm._v(_vm._s(_vm.$t("feedback")))]
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.logout($event)
                    }
                  }
                },
                [_vm._v("Uitloggen")]
              )
            ])
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("h3", [_vm._v("Inbox")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("h3", [_vm._v("Beheer")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("h3", [_vm._v("Inbox")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("h3", [_vm._v("Beheer")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("h3", [_vm._v("Beheer")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("h3", [_vm._v("Service")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }